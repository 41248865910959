export const imageUrlAdapter = (data) => {
  if (!data?.original) return;

  let extension = data?.extension;

  if (extension !== 'svg' && data?.formats?.length) {
    extension = `${extension}.${data.formats[0]}`;
  }

  return `${data.original}.${extension}`;
};

export const imageAdapter = (dataDesktop, dataMobile) => {
  if (!dataDesktop?.image && !dataMobile?.image) return;

  return {
    srcDesktop: imageUrlAdapter(dataDesktop?.image),
    srcMobile: imageUrlAdapter(dataMobile?.image),
  };
};
